import React from 'react';
import { graphql, Link } from 'gatsby';
import _get from 'lodash/get';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

const IndexPage = ({ data }) => {
  const posts = _get(data, 'allMarkdownRemark.edges');
  return (
    <Layout>
      <Seo title="Home" />
      {posts.map(({ node: { frontmatter, fields, timeToRead } }) => (
        <article key={fields.slug}>
          <header>
            <h3
              style={{
                color: '#329ea8',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '1.75rem',
                marginBottom: '0.44rem',
              }}
            >
              <Link style={{ boxShadow: 'none' }} to={fields.slug}>
                {frontmatter.title}
              </Link>
            </h3>
            <small>
              {new Date(frontmatter.date).toLocaleDateString('it-IT', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}{' '}
              • 📓 Lettura da {timeToRead} min
            </small>
          </header>
          <p>{frontmatter.spoiler}</p>
        </article>
      ))}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            date
            spoiler
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
